header {
    height: 135px;
    border-bottom: 3px solid rgb(231, 0, 14) ;
    width: 100%;
    max-width: 1920px;
    box-sizing: border-box;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }
  header > .mainLogo{
    width: max-content;
    height: max-content;
    margin-left: 30px;
    margin-top: 41px;
    margin-right: 51px;
  }

  header .tabList{
    display: flex;
    max-width: 1485px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  header .tabList a {
    border-right: 2px solid rgb(231, 0, 14);
    height: 76px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-size: 40.5px;
    font-family: "Myriad Pro";
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 0.6;
    text-decoration: none;
  }
  header .tabList a:last-child{
    border-right: none;
    color: rgba(0, 0, 0, 0.329);
    pointer-events: none;
  }

  .header .tabList a:hover{
    cursor: pointer;
    opacity: 0.8;
  }

  @media (max-width: 1550px) {
    header .tabList a {
      font-size: 35px;
    }
  }

  @media (max-width: 1350px) {
    header .tabList a {
      font-size: 28px;
      height: 56px;
    }
  }

  @media (max-width: 1200px) {
    header {
      height: auto;
      flex-direction: column;
      background: linear-gradient(to right,#990a0f, #ce1219);
    }
    header .tabList{
      background-color: #f2f0f0;
      height: 70px;
    }
    header .mainLogo{
      margin-left: auto;
      margin-right: auto;
      max-width: 544px;
      width: 100%;
      margin-bottom: 24px;
      margin-top: 38px;
    }
    header .tabList a {
      border-right: 4px solid rgb(231, 0, 14);
      height: 38px;
    }
  }

  @media (max-width: 800px) {
    header .tabList a {
      font-size: 20px;
    }
  }

  @media (max-width: 600px) {
    header {
      height: auto;
    }
    header .mainLogo{
      max-width: 320px;
      margin-bottom: 24px;
      margin-top: 38px;
    }
    header .tabList a {
      border-right: 3px solid rgb(231, 0, 14);
      font-size: 14px;
    }
  }

  @media (max-width: 440px) {
    header .tabList a {
      border-right: 3px solid rgb(231, 0, 14);
      font-size: 10px;
    }
  }

  