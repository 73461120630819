.mainPage {
    background-color: #f2f0f0;
    display: flex;
    position: relative;
    max-height: 1080px;
}

.mainPage .leftBanner {
    margin-left: 0;
    margin-right: auto;
}

.mainPage .content {
    width: 100%;
    padding-top: 135px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.mainPage .content>div:first-child {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.mainPage .mainTitle {
    position: absolute;
    left: 48px;
    top: 150px;
}

.mainPage .pictograms {
    position: absolute;
    bottom: 18px;
    left: 18px;
}

.mainPage .content .prizes {
    margin-left: 6.5%;
    margin-bottom: 71px;
    margin-right: 25px;
    margin-top: -50px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
}

.mainPage .prizes>img {
    
    height: auto;
    min-width: 0;
    
}

.mainPage .content .carImage {
    margin-top: 80px;
    margin-left: 65px;
    max-width: calc(100% - 434px);
    height: auto;
    flex-shrink: 1;
}

.mainPage .prizes .voucherImage {
    margin-bottom: auto;
    margin-right: 32px;
    margin-top: 0;
    width: 27%;
}

.mainPage .prizes .fridgeImage {
   margin-left: -90px;
   margin-bottom: 1.1%;
   width: 20.3%;
}

.mainPage .prizes .bagImage {
    margin-right: 0;
    margin-left: auto;
    width: 56.3%;
}

.mainPage .textHolder {
    display: flex;
    flex-direction: column;
    margin-right: 45px;
    margin-top: 41px;
    width: max-content;
}

.mainPage .textHolder .textItem {
    font-family: "Myriad Pro";
    font-weight: 600;
    text-transform: uppercase;
    line-height: 0.99;
    text-align: right;
    margin-bottom: 35px;
}

.mainPage .textHolder .textItem>div:first-child {
    font-size: 25px;
    color: rgb(0, 2, 5);

}

.mainPage .textHolder .textItem>div:last-child {
    color: #da2128;
    font-size: 40.5px;
    text-wrap: nowrap;
}

.mainPage .mobileFooter {
    display: none;
}
.mainPage .mobilePrizes {
    display: none;
}

.mainPage .desktopIcons {
    position: absolute;
    bottom: 32px;
    right: 46px;
    display: flex;
}

.mainPage .desktopIcons a:first-child {
    margin-right: 8px;
}

.mainPage .desktopIcons a {
    width: 37px;
    height: 37px;
}

.mainPage .desktopIcons img {
    width: 37px;
    height: 37px;
    filter: brightness(0) saturate(100%) invert(0%) sepia(98%) hue-rotate(222deg) contrast(101%);
}

.soonStarting {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 434px);
    height: 100%;
    z-index: 55;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.soonMain {
    font-size: 204px;
    line-height: 1.1;
    font-family: "Myriad Pro";
    font-weight: 600;
    color: rgb(255, 255, 255);
    background-color: rgb(213, 12, 20);
    border-radius: 20px;
    width: max-content;
    height: max-content;
    padding: 15px 151px 16px 151px;
    margin-top: 419px;
    margin-bottom: 50px;
}

.soonText {
    font-size: 46px;
    font-family: "Myriad Pro";
    font-weight: 600;
    color: rgb(255, 255, 255);
    line-height: 1.1;
}

.soonTextMobile {
    display: none;
}

.mainPage .desktopPrizes{
    display: block;
}

@media (max-width: 1550px) {
    .mainPage .content .carImage {
        margin-left: 45px;
        max-width: calc(100% - 334px);
    }

    .mainPage .textHolder .textItem>div:first-child {
        font-size: 22px;
    }

    .mainPage .textHolder .textItem>div:last-child {
        font-size: 35px;
    }

    .mainPage .content .prizes {
        margin-left: 45px;
    }

    .soonMain {
        font-size: 153px;
        padding: 15px 101px 16px 101px;
        margin-bottom: 50px;
    }

    .soonText {
        font-size: 34.5px;
    }
}

@media (max-width: 1350px) {
    .mainPage .mobilePrizes{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -7.5%;
    }
    .mainPage .content > div .mobilePrizes{
        order: 2;
        width: 99%;
        margin-top: -215px;
    }
    .mainPage .content > div .mobilePrizes .voucherImage{
        margin-right: 12%;
        margin-left: auto;
        width: 33%;
        z-index: 15;
    }
    .mainPage .desktopPrizes{
        display: none;
    }
    .mainPage .prizes{
        display: none !important;
    }
    .mainPage .content>div:first-child {
        flex-direction: column;
    }
    .mainPage .mobilePrizes .bagImage{
        order: 1;
        width: 63%;
        margin-left: 10%;
    }
    .mainPage .mobilePrizes .fridgeImage{
        order: 2;
        width: 21%;
        margin-left: 5%;
        margin-top: -6.5%;
    }

    .mainPage .content .carImage {
        order: 3;
        margin-top: -5%;
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
    }

    .mainPage .textHolder {
        order: 1;
    }

    .mainPage .textHolder .textItem {
        text-align: left;
        margin-left: 45px;
        margin-bottom: 15px;
    }
}

@media (max-width: 1200px) {
    .mainPage {
        max-height: max-content;
    }
    .mainPage .content > div .mobilePrizes{
        margin-bottom: 0;
    }
    .mainPage .mobilePrizes{
        margin-bottom: 80px;
    }

    .mainPage .leftBanner {
        display: none;
    }

    .mainPage .content {
        padding-top: 270px;
    }

    .mainPage .pictograms {
        display: none;
    }

    .mainPage .mobileFooter {
        background: linear-gradient(to right, #990a0f, #ce1219);
        height: 276px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .mainPage .mainTitle {
        display: none;
    }

    .mainPage .mobileMainTitle {
        font-family: "Gilroy";
        color: rgb(255, 255, 255);
        font-weight: 900;
        line-height: 1;
        font-size: 58px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: -46%;
    }

    .mainPage .mobilePictograms {
        margin-left: 33px;
        z-index: 5;
    }

    .mainPage .mobileIcons {
        margin-right: 33px;
        display: flex;
        flex-direction: column;
    }

    .mainPage .mobileIcons>a:first-child {
        margin-bottom: 8px;
    }

    .mainPage .textHolder .textItem>div:first-child {
        font-size: 25.5px;
    }

    .mainPage .textHolder .textItem>div:last-child {
        font-size: 45px;
    }

    .mainPage .textHolder .textItem {
        margin-bottom: 30px;
    }

    .mainPage .desktopIcons {
        display: none;
    }

    .soonStarting {
        top: 162px;
        width: 100%;
        height: calc(100% - 438px);
    }

    .soonMain {
        margin-top: 250px;
    }
}

@media (max-width: 1050px) {

    .mainPage .mobilePictograms {
        max-width: 150px;
    }

    .mainPage .textHolder .textItem>div:first-child {
        font-size: 22.5px;
    }

    .mainPage .textHolder .textItem>div:last-child {
        font-size: 40px;
    }

    .mainPage .content .prizes {
        top: 667px;
    }

    .mainPage .prizes>img:first-child {
        width: 438px;
    }

    .mainPage .prizes>.fridgeImage {
        max-width: 240px;
    }

    .mainPage .prizes>.bagImage {
        max-width: 357px;
        margin-bottom: 0;
    }

    .soonStarting {
        height: calc(100% - 438px);
    }
}

@media (max-width: 1000px) {
    .mainPage .content .prizes {
        top: 667px;
        margin-left: 0;
        max-width: 58%;
    }
    .mainPage .mobileMainTitle{
        width: 410px;
        bottom: -41%;
    }
    .mainPage .textHolder {
        margin-right: 0;
    }
    .mainPage .mobileFooter{
        height: 240px;
    }

    .mainPage .prizes>img:first-child {
        width: 438px;
    }

    .mainPage .prizes>.fridgeImage {
        width: 35%;
    }

    .mainPage .prizes>.bagImage {
        width: 51%;
    }
    .soonStarting {
        height: calc(100% - 402px);
    }
}

@media (max-width: 750px) {
    .mainPage .content > div .mobilePrizes{
        margin-top: -150px;
    }
    .mainPage .content{
        padding-top: 230px;
    }
    .mainPage .mobileMainTitle{
        width: 300px;
        bottom: -34%;
    }

    .mainPage .mobilePictograms {
        max-width: 120px;
    }

    .mainPage .mobileFooter {
        height: 210px;
    }

    .mainPage .mobileIcons {
        max-width: 40px;
    }

    .mainPage .mobileIcons img {
        width: 35px;
        height: 35px;
    }

    .mainPage .textHolder .textItem {
        margin-left: 10px;
        margin-bottom: 25px;
    }

    .mainPage .textHolder .textItem>div:first-child {
        font-size: 18px;
    }

    .mainPage .textHolder .textItem>div:last-child {
        font-size: 30px;
    }

    .mainPage .content .prizes {
        top: 627px;
        max-width: 65%;
    }

    .mainPage .prizes>img:first-child {
        max-width: 375px;
    }

    .soonStarting {
        height: calc(100% - 372px);
    }

    .soonMain {
        margin-top: 100px;
        font-size: 100px;
    }

    .soonText {
        display: none;
    }

    .soonTextMobile {
        display: flex;
    }
}

@media (max-width: 600px) {
    .mainPage .content > div .mobilePrizes{
        margin-top: -100px;
    }
    .mainPage .mobileMainTitle{
        width: 230px;
        bottom: -36%;
    }
    .mainPage .content .carImage {
        max-width: 90%;
        margin-left: 2%;
    }
    .mainPage .mobilePrizes .bagImage{
        order: 1;
        width: 73%;
        margin-left: 6%;
    }
    .mainPage .mobilePrizes .fridgeImage{
        order: 2;
        width: 25%;
        margin-left: 0;
        margin-top: -7.5%;
    }
    .mainPage .mobilePrizes{
        margin-bottom: 40px;
    }

    .mainPage .textHolder {
        margin-top: 25px;
    }
   

    .mainPage .content {
        padding-top: 195px;
    }

    .mainPage .mobilePictograms {
        max-width: 80px;
    }

    .mainPage .mobileFooter {
        height: 150px;
    }

    .mainPage .content .prizes {
        top: 567px;
        max-width: 60%;
    }

    .mainPage .prizes>img:first-child {
        max-width: 100%;
    }

    .mainPage .textHolder .textItem>div:first-child {
        font-size: 15px;
    }

    .mainPage .textHolder .textItem>div:last-child {
        font-size: 26px;
    }

    .soonStarting {
        top: 122px;
        height: calc(100% - 272px);
    }

    .soonMain {
        font-size: 80px;
        padding-left: 45px;
        padding-right: 45px;
        margin-bottom: 25px;
    }

    .soonTextMobile {
        font-size: 24px;
    }
}

@media (max-width: 460px) {
    .mainPage .content .prizes {
        top: 547px;
        max-width: 55%;
    }
    .mainPage .content > div .mobilePrizes{
        margin-top: -80px;
    }

    .mainPage .textHolder .textItem {
        margin-bottom: 15px;
    }

    .mainPage .textHolder .textItem>div:first-child {
        font-size: 12px;
    }

    .mainPage .textHolder .textItem>div:last-child {
        font-size: 20px;
    }

    .mainPage .mobileMainTitle{
        width: 200px;
        bottom: -32%;
    }
}

@media (max-width: 400px) {
    .mainPage .mobilePictograms {
        margin-left: 15px;
        width: 70px;
    }

    .mainPage .mobileIcons {
        margin-right: 15px;
    }
    .mainPage .mobileMainTitle{
        width: 180px;
        bottom: -28%;
    }
}